
:root {
  
    --primary: #02B2B2;
    --light: #135DDF;
    --dark: #174E71;
}

html{
    background-color: #FFFFFF;
  }
  
*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}


.text-lg-end{text-align:center !important}

.text-lg-start {
    
    text-align: center !important;
}
.shape1{
background: url(../img/Shape1.png);



background-position:top center;
background-repeat: no-repeat;


background-size: contain;

}




.shape4 {
     
    background-image: url(../img/shape4.png) ;
  
    background-position:center ;
    background-repeat: no-repeat;
    background-size: auto;
  
  

}
.marg{
    
  align-items: center;
}
.testing{
    background-image: url(../img/shapes.png);
    background-position:left ;
    background-repeat: no-repeat;
    background-size: contain;

}
.testing8{
    background-image: url(../img/shape3.png);
    background-position:center ;
    background-repeat: no-repeat;
    background-size: contain;

}
.ft2{
 
    color: #02B2B2;
}

.lol{
  text-align:  center;
}
.ft{

    color: #0E3A88;
}
.navbar-dark .navbar-toggler {
    color: #0E3A88;}
.bg-dark {

    background-color: #FDFEFF  !important
}
.testing3{
    background-image: url(../img/Path1.png);
    background-position:left  bottom;
    background-repeat: no-repeat;
    background-size: auto;
 margin-left: 35px;
}


.testing6{
    background-image: url(../img/Path354.png);
    background-position:center   ;
    background-repeat: no-repeat;
    background-size: contain;

  
}

.testing2{
    background-image: url(../img/shape2.png);
    background-position:left bottom ;
    background-repeat: no-repeat;
    background-size: auto;
    padding: 25px;

}

.ft3{

    border: 2px solid white;
    border-radius: 10px;
}

.test2{

    width: 500px;
            height: 200px;
       
object-fit: none;
            object-position: left bottom;
}



.text-primary {
    color: #FFCE00 !important;
}
.ff-secondary {
    font-family: 'Montserrat', sans-serif;
}

.fw-medium {
    font-weight: 600 !important;
}

.fw-semi-bold {
    font-weight: 700 !important;
}

.back-to-top {
    position: fixed;
    display: none;
    right: 45px;
    bottom: 45px;
    z-index: 99;
}


/*** Spinner ***/
#spinner {
  
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s ease-out, visibility 0s linear .5s;
    z-index: 99999;
}

#spinner.show {
    transition: opacity .5s ease-out, visibility 0s linear 0s;
    visibility: visible;
    opacity: 1;
}


/*** Button ***/
.btn {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    text-transform: inherit;
    transition: .5s;
}
.btn-check:focus+.btn-primary, .btn-primary:focus {
    color: #000;

    box-shadow: 0 0 0 0.2rem rgb(250, 209, 48);
}

.btn.btn-primary,
.btn.btn-secondary {
    border-color:#02B2B2;
    background-color:#02B2B2;
    border-radius:  42px;
    color: #FFFFFF;
}

.btn-square {
    width: 38px;
    height: 38px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 48px;
    height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    border-radius: 2px;
}


/*** Navbar ***/
.navbar-dark .navbar-nav .nav-link {
    position: relative;
    margin-left: 25px;
    padding: 35px 0;
    font-size: 15px;
    color: var(--light) !important;
    text-transform: inherit;
    font-weight: 500;
    outline: none;
    transition: .5s;
}
.box {
    display: flex;
    align-items: flex-start;
  }
.test{
   color:#174E71;
   font-size: 50px;
  
}

.sticky-top.navbar-dark .navbar-nav .nav-link {
    padding: 20px 0;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
    color: var(--primary) !important;
}

.navbar-dark .navbar-brand img {
    max-height: 60px;
    transition: .5s;
}

.sticky-top.navbar-dark .navbar-brand img {
    max-height: 45px;
}

@media (max-width: 991.98px) {
    .sticky-top.navbar-dark {
        position: relative;
    }

    .navbar-dark .navbar-collapse {
        margin-top: 15px;
        border-top: 1px solid rgba(255, 255, 255, .1)
    }

    .navbar-dark .navbar-nav .nav-link,
    .sticky-top.navbar-dark .navbar-nav .nav-link {
        padding: 10px 0;
        margin-left: 0;
    }

    .navbar-dark .navbar-brand img {
        max-height: 45px;
    }
}

@media (min-width: 992px) {
   
.text-lg-end {
    text-align: center !important;
}


.text-lg-start {
    text-align: center !important;
}
    .navbar-dark {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 999;
        background: #FFFFFF !important;
    }
    
    .sticky-top.navbar-dark {
        position: fixed;
        background: #FFFFFF !important;
    }
}


/*** Hero Header ***/



.hero-header {

    /*background: linear-gradient(rgba(15, 23, 43, .9), rgba(255, 255, 255, 0.9)), url(../img/Path1.png);*/
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}


@keyframes imgRotate { 
    100% { 
        transform: rotate(360deg); 
    } 
}

.breadcrumb-item + .breadcrumb-item::before {
    color: rgba(255, 255, 255, .5);
}


/*** Section Title ***/
.section-title {
    color:#135DDF;
    position: relative;
    display: inline-block;
}

.section-title::before {
   
    content: "";
    width: 45px;
    height: 2px;
    top: 50%;
    left: -55px;
    margin-top: -1px;
    background: var(--primary);
}

.section-title::after {

    content: "";
    width: 45px;
    height: 2px;
    top: 50%;
    right: -55px;
    margin-top: -1px;
    background: var(--primary);
}

.section-title.text-start::before,
.section-title.text-end::after {
    display: none;
}


/*** Service ***/
.service-item {
  
    box-shadow: 0 0 45px rgba(0, 0, 0, .08);
    transition: .5s;
}



.service-item * {
    transition: .5s;
}

.service-item:hover * {
    color: var(--light) !important;
}
.mb-5 {
    color:#135DDF;
}

/*** Food Menu ***/

.nav-pills .nav-item .active {
    
    border-bottom: 2px solid var(--primary);
}


/*** Youtube Video ***/
.video {
   
    position: relative;
    height: 100%;
    min-height: 500px;
    background: url(../img/imagevideo.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

.video .btn-play {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    box-sizing: content-box;
    display: block;
    width: 32px;
    height: 44px;
    border-radius: 50%;
    border: none;
    outline: none;
    padding: 18px 20px 18px 28px;
}

.video .btn-play:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 100px;
    height: 100px;
    background:#02B2B2;
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
}

.video .btn-play:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 100px;
    height: 100px;
    background: transparent;
    border-radius: 50%;
    transition: all 200ms;
}

.video .btn-play img {
    position: relative;
    z-index: 3;
    max-width: 100%;
    width: auto;
    height: auto;
}

.video .btn-play span {
    display: block;
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    border-left: 32px solid #FFCE00;
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
        opacity: 0;
    }
}

#videoModal {
  
    z-index: 99999;
}

#videoModal .modal-dialog {
    position: relative;
    max-width: 800px;
    margin: 60px auto 0 auto;
}


#videoModal .modal-body {
    
    position: relative;
    padding: 0px;
}

#videoModal .close {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 0px;
    top: -30px;
    z-index: 999;
    font-size: 30px;
    font-weight: normal;
    color: #FFFFFF;
    background: #000000;
    opacity: 1;
}


/*** Team ***/
.team-item {
    box-shadow: 0 0 45px rgba(0, 0, 0, .08);
    height: calc(100% - 38px);
    transition: .5s;
}

.team-item img {
    transition: .5s;
}

.team-item:hover img {
    transform: scale(1.1);
}

.team-item:hover {
    height: 100%;
}

.team-item .btn {
    border-radius: 38px 38px 0 0;
}


/*** Testimonial ***/
.testimonial-carousel .owl-item .testimonial-item,
.testimonial-carousel .owl-item.center .testimonial-item * {
    transition: .5s;
}

.testimonial-carousel .owl-item.center .testimonial-item {
    background: var(--primary) !important;
    border-color: var(--primary) !important;
}

.testimonial-carousel .owl-item.center .testimonial-item * {
    color: var(--light) !important;
}

.testimonial-carousel .owl-dots {
    margin-top: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.testimonial-carousel .owl-dot {
    position: relative;
    display: inline-block;
    margin: 0 5px;
    width: 15px;
    height: 15px;
    border: 1px solid #CCCCCC;
    border-radius: 15px;
    transition: .5s;
}

.testimonial-carousel .owl-dot.active {
    background: var(--primary);
    border-color: var(--primary);
}


/*** Footer ***/
.container-fluid{
    background-color: red;
}
.footer{
    
    background-color: #174E71;
}
.footer .btn.btn-social {
    margin-right: 5px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light);
    border: 1px solid #FFFFFF;
    border-radius: 35px;
    transition: .3s;
}

.footer .btn.btn-social:hover {
    color: var(--primary);
}

.footer .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
}

.footer .btn.btn-link::before {
    position: relative;
    content: "\f105";
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    margin-right: 10px;
}

.footer .btn.btn-link:hover {
    letter-spacing: 1px;
    box-shadow: none;
}

.footer .copyright {
    padding: 25px 0;
    font-size: 15px;
    border-top: 1px solid rgba(256, 256, 256, .1);
}

.footer .copyright a {
    color: var(--light);
}

.footer .footer-menu a {
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid rgba(255, 255, 255, .1);
}

.footer .footer-menu a:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: none;
}
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 110%;
  
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 25px;
    border: 2px solid #fff;
   
    outline: 0;
}


.container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1450px;
}
.modal-header {
    border-radius: 25px;
    border: 2px solid #fff;
  
  
}