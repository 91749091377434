.sectionBack {
  background-image: url("../../assets/img/carouselHeader3.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  width: 100%;
  margin-top: -50px;
  background-size: cover;
  height: auto;
  overflow: hidden;
  cursor: pointer;
}

#contentContainer {
  position: relative;
}

#carouselContainer {
  width: 350px;
  height: auto;
  margin: auto;
  margin-top: 66px;
  margin-bottom: 30px;
}

.carouselItem {
  height: 180px;
  border: 3px solid rgba(255, 255, 255, 0.5);
}

.carouselItemInner {
  width: 320px;
  height: 130px;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.75);
  border: 10px solid rgba(255, 255, 255, 0.5);
  color: aqua;
  font-size: 72px;
  left: 50%;
  top: 50%;
  margin-left: -160px;
  margin-top: -90px;
  text-align: center;
  padding-top: 50px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  height: 50%;
}

.swiper-slide img {
  display: block;
  width: 100%;
  box-shadow: none;
}

.swiper .swiper-cube-shadow {
  filter: blur(80px);
  -webkit-filter: blur(80px);
}

@media screen and (max-width: 992px) {
  .sectionBack{
    height: auto;
  }
}


@media screen and (max-width:320px) {
  #carouselContainer {
    width: 100%;
  }
}